










































































































import Vue from "vue";
import { get_color_by_name } from "@/utils/colors";

export default Vue.extend({
  name: "InstituteDashboardNumbers",
  data() {
    return {
      total_faculty_icon: require("@/assets/icons/bold/personalcard.svg"),
      total_alumni_icon: require("@/assets/icons/bold/teacher.svg"),
      total_students_icon: require("@/assets/icons/bold/people.svg")
    };
  },
  methods: {
    transform_faculty_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("danger-light");
      for (let node of icon.children) node.setAttribute("fill", color);
      return icon;
    },
    transform_alumni_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("primary-light");
      for (let node of icon.children) node.setAttribute("fill", color);
      return icon;
    },
    transform_total_students_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("pink");
      for (let node of icon.children) node.setAttribute("fill", color);
      return icon;
    }
  }
});
