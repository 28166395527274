



























































































































import Vue from "vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import SearchAnything from "@/components/recruiter/dashboard/SearchAnything.vue";
import InstituteDashboardNumbers from "@/components/modules/institute/InstituteDashboardNumbers.vue";
import GenderStatistics from "@/components/modules/institute/GenderStatistics.vue";
import CandidateWithRating from "@/components/shared/CandidateWithRating.vue";
import IndividualIndustry from "@/components/modules/institute/IndividualIndustry.vue";

export default Vue.extend({
  name: "InstituteDashboard",
  components: {
    IndividualIndustry,
    CandidateWithRating,
    GenderStatistics,
    InstituteDashboardNumbers,
    SearchAnything,
    HelloUser
  },
  data() {
    return {
      selected_filter: "weekly",
      talents_data: [
        {
          name: "Zubair Asif",
          designation: "Senior Full Stack Developer",
          rating: "4.5",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/hcms/12278378383597-sheraz-ali.jpg"
        },
        {
          name: "Zain Ahmad",
          designation: "Front-End Developer",
          rating: "4.3",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/hcms/1678431376997-image_2023_03_10T06_47_17_733Z.png"
        },
        {
          name: "Hammad Azhar",
          designation: "SQA analyst",
          rating: "3.1",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/hcms/1678436225757-Hafiz-Muhammad-Zubair.png"
        },
        {
          name: "Muhammad Bilal",
          designation: "Back-End Developer",
          rating: "2.2",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/hcms/profile-5.jpg"
        }
      ],
      faculty_data: [
        {
          name: "Muhammad Usama",
          designation: "Full Stack Developer",
          rating: "4.4",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/hcms/profile-6.jpg"
        },
        {
          name: "Nimra Nadeem",
          designation: "Graphic Designer",
          rating: "3.0",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/FlyDubai-Dev/1677307639289-Nimra%20Nadeem.jpg"
        },
        {
          name: "Saif Ullah Khalid",
          designation: "Machine Learning Engineer",
          rating: "2.5",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/hcms/1677306055385-saif-ullah-khalid.jpg"
        },
        {
          name: "Zohaib Arshid",
          designation: "Front-End Developer",
          rating: "2.9",
          avatar:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/avatars/hcms/1677075124164-ZOHAIB-ARSHID.jpeg"
        }
      ],
      hiring_industries: [
        {
          title: "Information Technology",
          description:
            "We are looking for a computer scientist to join our team....",
          active: true,
          applied: 10,
          icon: require("@/assets/icons/bold/monitor-mobile.svg"),
          color: "#A162F7"
        },
        {
          title: "Media",
          description:
            "We are looking for a computer scientist to join our team....",
          active: false,
          applied: 17,
          icon: require("@/assets/icons/bold/video.svg"),
          color: "#FF8473"
        },
        {
          title: "Healthcare",
          description:
            "We are looking for a computer scientist to join our team....",
          active: false,
          applied: 22,
          icon: require("@/assets/icons/bold/health.svg"),
          color: "#27D86D"
        },
        {
          title: "Electronics",
          description:
            "We are looking for a computer scientist to join our team....",
          active: false,
          applied: 8,
          icon: require("@/assets/icons/bold/lamp-charge.svg"),
          color: "#FFB815"
        },
        {
          title: "Training & Education",
          description:
            "We are looking for a computer scientist to join our team....",
          active: false,
          applied: 20,
          icon: require("@/assets/icons/bold/keyboard-open.svg"),
          color: "#33BFFF"
        },
        {
          title: "Financial Services",
          description:
            "We are looking for a computer scientist to join our team....",
          active: false,
          applied: 17,
          icon: require("@/assets/icons/bold/chart-square.svg"),
          color: "#EE49FD"
        },
        {
          title: "Administration",
          description:
            "We are looking for a computer scientist to join our team....",
          active: false,
          applied: 26,
          icon: require("@/assets/icons/bold/user-octagon.svg"),
          color: "#A162F7"
        }
      ]
    };
  }
});
